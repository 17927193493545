import { useEffect, useState } from "preact/hooks";
import ClipboardButton from "../components/ClipboardButton.tsx"; // Import ClipboardButton
import { startCountdown, formatRemainingTime } from "../utils/tokenUtils.ts"; // Import Token Utility functions

interface TokenMonitorModalProps {
  isOpen: boolean;
  onClose: () => void;
  fetchTokensForMonitor: () => void;
  accessToken: string | null;
  idToken: string | null;
  refreshToken: string | null;
}

const TokenMonitorModal = ({
  isOpen,
  onClose,
  fetchTokensForMonitor,
  accessToken,
  idToken,
  refreshToken,
}: TokenMonitorModalProps) => {
  const [remainingTime, setRemainingTime] = useState<number | null>(null);

  useEffect(() => {
    let stopCountdown: (() => void) | null = null;

    if (isOpen && accessToken) {
      fetchTokensForMonitor();

      // Start the countdown when the modal opens
      stopCountdown = startCountdown(accessToken, setRemainingTime, () => {
        setRemainingTime(0);  // When countdown ends, set time to 0
      });
    }

    // Stop countdown when modal closes
    return () => {
      if (stopCountdown) stopCountdown();  // Clean up the countdown
    };
  }, [isOpen, accessToken]);

  if (!isOpen) return null; // Don't render anything if the modal is not open

  return (
    <div
      id="token-monitor-background"
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      onClick={onClose} // Close monitor on background click
    >
      <div
        class="bg-white rounded-lg shadow-lg p-4 w-full max-w-md h-1/2 max-h-[50vh] overflow-y-auto"
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside modal
      >
        <div class="relative">
          <div class="flex justify-between items-center mb-2">
            <h2 class="text-xl font-bold">Token Monitor (just for demo)</h2>
            <button onClick={onClose} class="text-gray-600 hover:text-gray-700">
              &times;
            </button>
          </div>

          {/* Display remaining time if accessToken is present */}
          {accessToken && (
            <div class="flex justify-start mt-1">
              <div class="text-right mr-1">
                <strong>Remaining Time:</strong>
              </div>
              <div class="text-left text-slate-800 break-all max-w-full mr-2">
                {formatRemainingTime(remainingTime)}
              </div>
            </div>
          )}

          {/* Display tokens */}
          <div class="mt-1 text-[0.5rem] md:text-xs">
            <div class="flex justify-start">
              <div class="text-right mr-1">
                <strong>Access Token:</strong>
              </div>
              <div class="relative text-left text-slate-300 break-all max-w-full mr-2">
                {/* Access Token Value */}
                {accessToken ? accessToken : "none"}
                
                {/* ClipboardButton positioned in the top-right corner */}
                {accessToken && (
                  <div class="absolute right-0 top-0">
                    <ClipboardButton text={accessToken} />
                  </div>
                )}
              </div>
            </div>

            <div class="flex justify-start mt-1">
              <div class="text-right mr-1">
                <strong>ID Token:</strong>
              </div>
              <div class="text-left text-slate-300 break-all max-w-full">
                {idToken ? idToken : "none"}
              </div>
            </div>
            <div class="flex justify-start mt-1">
              <div class="text-right mr-1">
                <strong>Refresh Token:</strong>
              </div>
              <div class="text-left text-slate-300 break-all max-w-full">
                {refreshToken ? refreshToken : "none"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenMonitorModal;
