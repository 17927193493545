// tokenUtils.ts

export const decodeJWT = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  
    return JSON.parse(jsonPayload);
  };
  
  export const getRemainingTimeFromToken = (token: string): number | null => {
    if (!token) return null;
  
    try {
      const decodedToken = decodeJWT(token);
      const expirationTime = decodedToken.exp * 1000; // Convert exp to milliseconds
      const currentTime = Date.now();
      const timeLeft = expirationTime - currentTime;
  
      return timeLeft > 0 ? timeLeft : 0;
    } catch (error) {
      console.error("Error decoding token:", error);
      return null;
    }
  };
  
  // Countdown function that updates the remaining time every second
  export const startCountdown = (
    token: string,
    setRemainingTime: (time: number) => void,
    onEnd?: () => void
  ) => {
    const updateRemainingTime = () => {
      const timeLeft = getRemainingTimeFromToken(token);
      if (timeLeft !== null && timeLeft <= 0 && onEnd) {
        setRemainingTime(0);  // Stop at 0
        onEnd();  // Callback when countdown ends
      } else {
        setRemainingTime(timeLeft || 0); // Update remaining time
      }
    };
  
    // Start the interval to update the countdown every second
    const intervalId = setInterval(updateRemainingTime, 1000);
  
    // Return a function to stop the countdown
    return () => clearInterval(intervalId);
  };
  
  // Format remaining time into a human-readable string
  export const formatRemainingTime = (milliseconds: number | null): string => {
    if (milliseconds === null || milliseconds <= 0) return 'Expired';
  
    const totalSeconds = Math.floor(milliseconds / 1000);
    const days = Math.floor(totalSeconds / (60 * 60 * 24));
    const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
    const seconds = totalSeconds % 60;
  
    const parts = [];
    if (days > 0) parts.push(`${days}d`);
    if (hours > 0) parts.push(`${hours}h`);
    if (minutes > 0) parts.push(`${minutes}m`);
    if (seconds > 0) parts.push(`${seconds}s`);
  
    return parts.length > 0 ? parts.join(' ') : 'Expired';
  };
  